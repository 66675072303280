import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../components/helper";
import { getStorage } from "../../components/utilityfunctions";

export const getCartItems = createAsyncThunk("cart/getCartItems", async () => {
  const response = await http.get("cart/" + getStorage("accountCode"));
  return response.data.data;
});

export const addToCart = createAsyncThunk("cart/addToCart", async (payload) => {
  payload = { ...payload, priceband: getStorage("priceband") }
  const response = await http.post("cart", payload);
  return response.data;
});

export const addFreeToCart = createAsyncThunk(
  "cart/addFreeToCart",
  async (payload) => {
     payload = payload.map((item) => ({
      ...item,
      priceband: getStorage("priceband"),
    }));
    const response = await http.post("freetocart", payload);
    return response.data;
  }
);

export const addProductsToCart = createAsyncThunk(
  "cart/addProductsToCart",
  async (payload) => {
    payload = payload.map((item) => ({
      ...item,
      priceband: getStorage("priceband"),
    }));
    const response = await http.post("addproductstocart", payload);
    return response.data;
  }
);

export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async (payload) => {
    payload = { ...payload, priceband: getStorage("priceband") }
    const response = await http.put("cart", payload);
    return response.data;
  }
);

export const deleteCartItem = createAsyncThunk(
  "cart/deleteCartItem",
  async (payload) => {
    const response = await http.post("deletecart", payload);
    return response.data;
  }
);

export const deleteFreeCartItem = createAsyncThunk(
  "cart/deleteFreeCartItem",
  async (payload) => {
    const response = await http.post("deletefreecart", payload);
    return response.data;
  }
);

export const clearCart = createAsyncThunk("cart/clearCart", async (payload) => {
  const response = await http.post("clearcart", payload);
  return response.data;
});

const initialState = {
  cartItems: [],
  switchTrigger: false,
  resetQuantity: false,
  status: "idle",
  updateCartStatus: "idle",
  addToCartStatus: "idle",
  deleteCartStatus: "idle",
  clearCartStatus: "idle",
  addFreeToCartStatus: "idle",
  addProductsToCartStatus: "idle",
  error: null,
};

const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    switchTriggerPopup(state, action) {
      state.switchTrigger = action.payload;
    },
    switchResetQuantity(state, action) {
      state.resetQuantity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cartItems = action.payload;
      })
      .addCase(getCartItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addToCart.pending, (state) => {
        state.addToCartStatus = "loading";
      })
      .addCase(addFreeToCart.fulfilled, (state) => {
        state.addFreeToCartStatus = "succeeded";
      })
      .addCase(addFreeToCart.pending, (state) => {
        state.addFreeToCartStatus = "loading";
      })
      .addCase(addToCart.fulfilled, (state) => {
        state.addToCartStatus = "succeeded";
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.addToCartStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCartItem.fulfilled, (state) => {
        state.updateCartStatus = "succeeded";
      })
      .addCase(updateCartItem.rejected, (state, action) => {
        state.updateCartStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCartItem.pending, (state) => {
        state.updateCartStatus = "loading";
      })
      .addCase(deleteCartItem.pending, (state) => {
        state.deleteCartStatus = "loading";
      })
      .addCase(deleteCartItem.fulfilled, (state) => {
        state.deleteCartStatus = "succeeded";
      })
      .addCase(deleteFreeCartItem.pending, (state) => {
        state.deleteCartStatus = "loading";
      })
      .addCase(deleteFreeCartItem.fulfilled, (state) => {
        state.deleteCartStatus = "succeeded";
      })
      .addCase(deleteCartItem.rejected, (state, action) => {
        state.deleteCartStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(clearCart.fulfilled, (state) => {
        state.clearCartStatus = "succeeded";
      })
      .addCase(clearCart.pending, (state) => {
        state.clearCartStatus = "loading";
      })
      .addCase(clearCart.rejected, (state, action) => {
        state.clearCartStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addProductsToCart.pending, (state) => {
        state.addProductsToCartStatus = "loading";
      })
      .addCase(addProductsToCart.fulfilled, (state) => {
        state.addProductsToCartStatus = "succeeded";
      })
      .addCase(addProductsToCart.rejected, (state, action) => {
        state.addProductsToCartStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { switchTriggerPopup, switchResetQuantity } = cartSlice.actions;
export default cartSlice.reducer;
